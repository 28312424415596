let timeout;

document.addEventListener('DOMContentLoaded', function () {
    projekteDebounce();
});

var projekteDebounce = debounce(function () {
    const letztesProjekt = document.querySelector('.projekte__projekt:last-child');
    const letztesProjektBild = letztesProjekt.querySelector('.projekte__bild');
    const letztesProjektBildHoehe = letztesProjektBild.clientHeight;
    const viewportHoehe = window.innerHeight;
    const dynamischerMargin = (50 * viewportHoehe / 100) - (letztesProjektBildHoehe / 2);

    if (window.matchMedia("(max-width: 767px)").matches) {
        letztesProjekt.style.marginBottom = dynamischerMargin + 'px';
        projekteMobile();
        console.log("mobile");
    } else {
        letztesProjekt.style.marginBottom = '27.5%';
        projekteDesktop();
        console.log("desktop");
    }
}, 200);

window.addEventListener('resize', () => {
    projekteDebounce();
});

function mouseEnterHandler() {
    const projekt = this.closest('.projekte__projekt');
    if (projekt) {
        projekt.setAttribute('data-hover', 'true');
        projekt.setAttribute('data-grain', 'false');
        projekt.style.zIndex = 10;
        clearTimeout(timeout);

        document.querySelectorAll('.projekte__projekt').forEach(projektElement => {
            if (projektElement !== projekt) {
                projektElement.setAttribute('data-grain', 'true');
                projektElement.style.zIndex = 0;
            }
        });
    }
}

function mouseLeaveHandler() {
    const projekt = this.closest('.projekte__projekt');
    if (projekt) {
        projekt.removeAttribute('data-hover');
        projekt.style.zIndex = 0;

        timeout = setTimeout(() => {
            document.querySelectorAll('.projekte__projekt').forEach(projektElement => {
                projektElement.removeAttribute('data-grain');
                projektElement.style.zIndex = 0;
            });
        }, 1000);
    }
}

function projekteMobile() {
    // Kill nur für die spezifischen ScrollTrigger (mobile Projekte)
    ScrollTrigger.getAll().forEach(trigger => {
        if (trigger.vars.trigger.classList.contains('projekte__projekt')) {
            trigger.kill();
        }
    });

    document.querySelectorAll('.projekte__projekt').forEach(projekt => {
        projekt.removeAttribute('data-hover');
        projekt.removeAttribute('data-grain');
        projekt.style.filter = 'blur(0px)';
        projekt.style.zIndex = 0;
    });
    
    document.querySelectorAll('.projekte__bild').forEach(bild => {
        bild.removeEventListener('mouseenter', mouseEnterHandler);
        bild.removeEventListener('mouseleave', mouseLeaveHandler);
    });

    let projekteProjekte = gsap.utils.toArray('.projekte__projekt');
    let projekteTitel = gsap.utils.toArray('.projekte__beschriftung--smartphone');

    projekteProjekte.forEach((projekteProjekt, index) => {
        let projektBeschriftung = document.querySelector('.projekte__beschriftung--smartphone');
        let projektTitel = projekteTitel[index + 1];
        let projekteProjektMargin = gsap.getProperty(projekteProjekt, "margin-bottom");
        let beruehrungspunkt = (projekteProjekt.offsetHeight - projekteProjektMargin) - projektBeschriftung.offsetHeight;

        let grainElementSmartphone = projekteProjekt.querySelector('.projekte__grain--smartphone');
        let grainElementDesktop = projekteProjekt.querySelector('.projekte__grain--desktop');

        // data-opacity initialisieren
        if (grainElementSmartphone) {
            gsap.set(grainElementSmartphone, { 'data-opacity': 1, opacity: 1 });
        } else if (grainElementDesktop) {
            gsap.set(grainElementDesktop, { 'data-opacity': 0, opacity: 0 });
        }

        const projekteProjektTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: projekteProjekt,
                start: () => `top+=${(projekteProjekt.offsetHeight + projektBeschriftung.offsetHeight) / 2} top+=85%`,
                end: () => `top+=${projekteProjekt.offsetHeight} top`,
                scrub: true,
                invalidateOnRefresh: true
            }
        });

        projekteProjektTimeline
            .to(projekteProjekt, {
                filter: 'blur(0px)',
                duration: 0.5,
                onUpdate: function () {
                    const progress = this.progress();
                    const opacityValue = 1 - progress;
                    projekteProjekt.setAttribute('data-opacity', opacityValue);

                    if (grainElementSmartphone) {
                        grainElementSmartphone.setAttribute('data-opacity', opacityValue);
                        gsap.set(grainElementSmartphone, { opacity: opacityValue });
                    }
                }
            })
            .to(projekteProjekt, {
                filter: 'blur(2px)',
                duration: 0.5,
                onUpdate: function () {
                    const progress = this.progress();
                    const opacityValue = progress;
                    projekteProjekt.setAttribute('data-opacity', opacityValue);

                    if (grainElementSmartphone) {
                        grainElementSmartphone.setAttribute('data-opacity', opacityValue);
                        gsap.set(grainElementSmartphone, { opacity: opacityValue });
                    }
                }
            }, 0.5);

        const projekteTitelTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: projekteProjekt,
                start: () => `top+=${beruehrungspunkt} top`,
                end: () => `top+=${projekteProjekt.offsetHeight} top`,
                scrub: true,
                invalidateOnRefresh: true,
            }
        });

        gsap.set(projekteTitel, { yPercent: 100 });
        gsap.set(projekteTitel[0], { yPercent: 0 });

        if (index === 0) {
            projekteTitelTimeline
                .to(projekteTitel[0], { yPercent: -100, duration: 0.5 })
                .to(projektTitel, { yPercent: 0, duration: 0.5 }, 0);
        } else {
            projekteTitelTimeline
                .to(projekteTitel[index], { yPercent: -100, duration: 0.5 }, 0)
                .to(projektTitel, { yPercent: 0, duration: 0.5 }, 0);
        }
    });
}

function projekteDesktop() {
    // Kill nur für die spezifischen ScrollTrigger (mobile Projekte)
    ScrollTrigger.getAll().forEach(trigger => {
        if (trigger.vars.trigger.classList.contains('projekte__projekt')) {
            trigger.kill();
        }
    });

    document.querySelectorAll('.projekte__projekt').forEach(projekt => {
        projekt.style.filter = 'blur(0px)';

        projekt.removeAttribute('data-grain');
        projekt.removeAttribute('data-opacity');
        projekt.style.zIndex = 0; 

        const grainElementSmartphone = projekt.querySelector('.projekte__grain--smartphone'); 
        if (grainElementSmartphone) {
            grainElementSmartphone.style.opacity = '0'; 
            grainElementSmartphone.removeAttribute('data-opacity'); 
        }
    });

    document.querySelectorAll('.projekte__bild').forEach(bild => {
        bild.addEventListener('mouseenter', mouseEnterHandler);
        bild.addEventListener('mouseleave', mouseLeaveHandler);
    });
}
